
<template>
  <div class="body add-vehicle">
    <p class="form-title">基本信息</p>
    <el-form
      :model="addForm"
      :rules="rules"
      :inline="true"
      ref="addForm"
      label-width="82px"
      label-position="left"
      class="demo-form-inline dialog-form dialog-form-inline"
      style="padding-bottom: 0"
    >
      <el-form-item label="车牌号：" prop="cph">
        <el-input
          v-model.trim="addForm.cph"
          placeholder="请输入车牌号"
        ></el-input>
      </el-form-item>

      <el-form-item label="所属企业：" prop="companyId">
        <companySelect
          ref="companySelect"
          :clearable="true"
          :searchable="true"
          :companyTree="companyList"
          :valueName="valueNameAdd"
          :value="valueAdd"
          @getValue="getAddGroupId"
        ></companySelect>
      </el-form-item>
      <!--车架号：编辑时不校验  -->
      <el-form-item label="车架号：" :prop="isvehicleNoEdit ? 'vehicleNo' : ''">
        <el-input
          v-model.trim="addForm.vehicleNo"
          placeholder="请输入车架号"
          :disabled="!isvehicleNoEdit"
        ></el-input>
      </el-form-item>

      <el-form-item label="车辆状态：" prop="vehicleStatus">
        <el-select clearable v-model.trim="addForm.vehicleStatus">
          <el-option
            v-for="item in vehicleStatusList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="车牌颜色：" prop="licensePlateColor">
        <el-select clearable v-model.trim="addForm.licensePlateColor">
          <el-option
            v-for="item in licensePlateColorList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="车辆类型：" prop="type">
        <el-select clearable v-model.trim="addForm.type">
          <el-option
            v-for="item in typeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="车身颜色：" prop="vehicleColor">
        <el-select clearable v-model.trim="addForm.vehicleColor">
          <el-option
            v-for="item in vehicleColorList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="动力类型：">
        <el-select clearable v-model.trim="addForm.powerType">
          <el-option
            v-for="item in powerTypeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="城市：">
        <el-cascader
          size="large"
          :options="cityList"
          v-model="addForm.city"
        ></el-cascader>
      </el-form-item>

      <el-form-item label="车型：" prop="model">
        <el-select clearable v-model.trim="addForm.model">
          <el-option
            v-for="item in modelList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="发动机号：" prop="engineNo">
        <el-input
          v-model.trim="addForm.engineNo"
          placeholder="请输入发动机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="座位数：">
        <el-row>
          <el-col :span="23"
            ><el-input-number
              v-model="addForm.seatNum"
              controls-position="right"
              :min="1"
              :max="199"
              class="add-vehicle-number"
            >
            </el-input-number
          ></el-col>
          <el-col :span="1"> <span>座</span></el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="安装时间：">
        <el-date-picker
          v-model="addForm.installDate"
          placeholder="选择日期"
          type="date"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 2 }"
          maxlength="500"
          show-word-limit
          placeholder="请输入内容"
          v-model.trim="addForm.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <p class="form-title form-title2">
      <span>车辆终端信息</span>
      <el-button type="primary" size="small" @click="onAddTerminal"
        >添加设备</el-button
      >
    </p>
    <el-form ref="form" :model="form" class="dialog-form dialog-form-table">
      <el-table :data="form.terminalData" class="deviceTable">
        <el-table-column
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column label="设备号" width="330">
          <template slot-scope="scope">
            <el-form-item
              :prop="`terminalData.${scope.$index}.deviceNo`"
              :rules="tableRules.deviceNo"
            >
              <el-select
                v-model="scope.row.deviceNo"
                filterable
                remote
                @change="onChangeDeviceNo(scope.$index, scope.row)"
                :remote-method="remoteMethod"
                placeholder="请输入关键字"
                cellclassname="carGroupSelect"
              >
                <el-option
                  v-for="(item, key) in deviceNoList"
                  :label="`${item.deviceNo}(${
                    item.serialNo ? item.serialNo : '暂无自编号'
                  })`"
                  :value="`${item.deviceNo}(${
                    item.serialNo ? item.serialNo : '暂无自编号'
                  })`"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="deviceTypeName"
          label="设备类型"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="SIMNo"
          label="SIM卡号"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="摄像头数量" width="220">
          <template slot-scope="scope">
            <el-form-item
              :prop="`terminalData.${scope.$index}.videoChanel`"
              :rules="tableRules.videoChanel"
            >
              <el-select v-model="scope.row.videoChanel">
                <el-option label="0" value="0"></el-option>
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>
                <el-option label="7" value="7"></el-option>
                <el-option label="8" value="8"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              sort="danger"
              @click="onDeleteTerminal(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div v-if="rowData">
      <p class="form-title form-title2">
        <span>行驶证信息</span>
      </p>
      <div class="head">
        <p>
          <span>行驶证号：</span>
          <span>{{ detailForm.travelCredentialsNumber }}</span>
        </p>
        <p>
          <span>行驶证生效时间：</span>
          <span>{{ detailForm.travelCredentialsStartDate }}</span>
        </p>
        <p>
          <span>行驶证失效时间：</span>
          <span>{{ detailForm.travelCredentialsEndDate }}</span>
        </p>
      </div>
      <p class="form-title form-title2">
        <span>道路运输证信息</span>
      </p>
      <div class="head">
        <p>
          <span>道路运输证号：</span>
          <span>{{ detailForm.roadCredentialsNumber }}</span>
        </p>
        <p>
          <span>道路运输证生效时间：</span>
          <span>{{ detailForm.roadCredentialsStartDate }}</span>
        </p>
        <p>
          <span>道路运输证失效时间：</span>
          <span>{{ detailForm.roadCredentialsEndDate }}</span>
        </p>
      </div>
      <p class="form-title form-title2">
        <span>保险信息</span>
      </p>
      <div class="head">
        <p>
          <span>承运险生效日期：</span>
          <span>{{ detailForm.insuranceStartDateCY }}</span>
        </p>
        <p>
          <span>承运险失效日期：</span>
          <span>{{ detailForm.insuranceEndDateCY }}</span>
        </p>
        <p>
          <span>交强险生效日期：</span>
          <span>{{ detailForm.insuranceStartDateJQ }}</span>
        </p>
        <p>
          <span>交强险失效日期：</span>
          <span>{{ detailForm.insuranceEndDateJQ }}</span>
        </p>
        <p>
          <span>商业险生效日期：</span>
          <span>{{ detailForm.insuranceStartDateSY }}</span>
        </p>
        <p>
          <span>商业险失效日期：</span>
          <span>{{ detailForm.insuranceEndDateSY }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  insertVehicle,
  updateVehicle,
  getBusinessCompanyTree,
  queryByDeviceNo,
  queryAreaList,
  queryByVehicleNo,
  getVehicleInfo,
  queryDictsByCodes
} from '@/api/lib/api.js'
import companySelect from '@/components/treeSelect/companySelect.vue'
import { checkPlateNumber, checkFrameNumver, formatDate } from '@/common/utils/index.js'

export default {
  components: {
    companySelect
  },
  props: {
    // 处理类型
    dealType: {
      type: Number
    },
    //编辑时暂存的车架号
    vehicleNo: {
      type: String
    },
    rowData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      isvehicleNoEdit: true,
      value: null,
      valueName: null,
      valueNameAdd: null,
      valueAdd: null,
      detailForm: {},
      addForm: {
        cph: '',
        vehicleNo: '',
        companyId: '',
        powerType: '',
        licensePlateColor: '',
        type: '',
        color: '',
        vehicleStatus: '1',
        engineNo: '',
        model: '',
        city: [],
        deviceNos: [],
        seatNum: null,
        installDate: new Date(),
        remark: null,
      },
      rules: {
        cph: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
          { validator: checkPlateNumber, trigger: 'blur' }
        ],
        vehicleNo: [
          { required: true, message: '请输入车架号', trigger: 'blur' },
          { validator: checkFrameNumver, trigger: 'blur' }
        ],
        companyId: [
          { required: true, message: '请选择所属企业', trigger: 'change' }
        ],
        powerType: [
          { required: true, message: '请选择动力类型', trigger: 'change' }
        ],
        licensePlateColor: [
          { required: true, message: '请选择车牌颜色', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择车辆类型', trigger: 'change' }
        ],
        color: [
          { required: true, message: '请选择车身颜色', trigger: 'change' }
        ],
        vehicleStatus: [
          { required: true, message: '请选择车辆状态', trigger: 'change' }
        ],
        city: [{ required: true, message: '请选择城市', trigger: 'change' }]
      },
      terminalData: [
        {
          deviceNo: '',
          deviceType: '',
          deviceTypeName: '',
          deviceModelId: '',
          SIMNo: '',
          videoChanel: ''
        }
      ],
      companyList: [],
      vehicleStatusList: [],
      powerTypeList: [],
      licensePlateColorList: [],
      typeList: [],
      vehicleColorList: [],
      modelList: [],
      deviceNoList: [],
      cityList: [],
      form: {
        terminalData: [
          {
            deviceNo: '',
            deviceType: '',
            deviceTypeName: '',
            deviceModelId: '',
            SIMNo: '',
            videoChanel: ''
          }
        ]
      },
      tableRules: {
        deviceNo: [{ required: true, message: '请选择', trigger: 'change' }],
        videoChanel: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  methods: {
    // 新增/修改车辆
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.$emit('onLoading', true)
              let data = { ...this.addForm }
              let deviceNos = []
              data.city = JSON.stringify(data.city)
              if (data.installDate) {
                data.installDate = formatDate(data.installDate)
              }

              this.form.terminalData.forEach((item) => {
                if (item.deviceNo) {
                  let deviceNo = item.deviceNo.split('(')[0]
                  deviceNos.push({
                    deviceNo: deviceNo,
                    deviceModelId: item.deviceModelId,
                    deviceModelType: item.deviceType,
                    videoChanel: item.videoChanel
                  })
                }
              })
              data.deviceNos = deviceNos

              if (this.vehicleNo) {
                updateVehicle(data).then((res) => {
                  if (res.code === 1000) {
                    this.$message.success('修改成功')
                    this.$emit('saveEnd')
                  } else {
                    this.$message.error(res.msg)
                  }
                  this.$emit('onLoading', false)
                })
              } else {
                insertVehicle(data).then((res) => {
                  if (res.code === 1000) {
                    this.$message.success('新增成功')
                    this.$emit('saveEnd')
                  } else {
                    this.$message.error(res.msg)
                  }
                  this.$emit('onLoading', false)
                })
              }
            } else {
              return false
            }
          })
        }
      })
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
    },
    // 查询省市接口
    getAreaList () {
      queryAreaList().then((res) => {
        if (res.code === 1000) {
          this.cityList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 模糊查询所有未绑定车辆终端
    remoteMethod (query) {
      if (!this.addForm.companyId) {
        this.$message.warning('请先选择企业')
        return
      }
      if (query === '') {
        this.deviceNoList = []
        return
      }
      this.loading = true
      this.onSearchDeviceNo(query)
    },
    // 根据终端号模糊搜索所有未绑定车辆终端
    onSearchDeviceNo (deviceNo) {
      queryByDeviceNo({
        deviceNo: deviceNo,
        companyId: this.addForm.companyId
      }).then((res) => {
        if (res.code === 1000) {
          this.deviceNoList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 选定设备号显示设备类型和sim卡号
    onChangeDeviceNo (index, row) {
      let deviceNo = row.deviceNo.split('(')[0]
      const item = this.deviceNoList.find(
        (element) => element.deviceNo === deviceNo
      )
      this.form.terminalData[index].SIMNo = item.sim || ''
      this.form.terminalData[index].deviceModelId = item.id || ''
      this.form.terminalData[index].deviceType = item.deviceModelType || ''
      this.form.terminalData[index].deviceTypeName = item.deviceName || ''
    },
    // 添加设备
    onAddTerminal () {
      this.form.terminalData.push({
        deviceNo: '',
        deviceType: '',
        deviceTypeName: '',
        SIMNo: '',
        videoChanel: ''
      })
    },
    // 删除设备
    onDeleteTerminal (i) {
      this.form.terminalData.splice(i, 1)
    },
    getAddGroupId (v) {
      this.addForm.companyId = v
    },
    //  查询车辆附加信息
    getVehicleInfo () {
      getVehicleInfo({ vehicleNo: this.rowData.vehicleNo }).then((res) => {
        if (res.code === 1000) {
          this.detailForm = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'VETY,CLZT,DLLX,CX,CPYS,CSYS' }).then(
        (res) => {
          if (res.code === 1000) {
            this.typeList = res.data.VETY
            this.vehicleStatusList = res.data.CLZT
            this.powerTypeList = res.data.DLLX
            this.modelList = res.data.CX
            this.licensePlateColorList = res.data.CPYS
            this.vehicleColorList = res.data.CSYS
          }
        }
      )
    }
  },
  created () {
    this.getDicts()
    this.getCompanyList()
    this.getAreaList()
    if (this.rowData) {
      this.getVehicleInfo()
    }
  },
  mounted () {
    /** 编辑 */
    if (this.vehicleNo) {
      this.addForm = { ...this.rowData }
      this.addForm.city = JSON.parse(this.rowData.city)
      this.addForm.installDate = this.rowData.installDate ? new Date(this.rowData.installDate) : null
      this.valueAdd = this.rowData.companyId
      this.valueNameAdd = this.rowData.companyName
      this.isvehicleNoEdit = false
      queryByVehicleNo({ vehicleNo: this.vehicleNo }).then((res) => {
        if (res.code === 1000) {
          if (res.data.length > 0) {
            this.form.terminalData = []
            res.data.forEach((element) => {
              this.form.terminalData.push({
                SIMNo: element.sim,
                deviceType: element.deviceModelType,
                deviceTypeName: element.deviceName,
                deviceModelId: element.id,
                deviceNo: `${element.deviceNo}(${element.serialNo ? element.serialNo : '暂无自编号'
                  })`,
                videoChanel: element.videoChanel
              })
            })
          } else {
            this.deviceNoList = []
            this.form.terminalData = [
              {
                deviceNo: '',
                deviceType: '',
                deviceTypeName: '',
                deviceModelId: '',
                SIMNo: '',
                videoChanel: ''
              }
            ]
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    } else {
      this.valueAdd = null
      this.valueNameAdd = null
      this.form.terminalData = [
        {
          deviceNo: '',
          deviceType: '',
          deviceTypeName: '',
          deviceModelId: '',
          SIMNo: '',
          videoChanel: ''
        }
      ]
      this.deviceNoList = []
      this.isvehicleNoEdit = true
    }
  }
}
</script>

<style lang="scss" scoped>
.add-vehicle {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed("n7");
      margin-bottom: 10px;
    }
    .form-title2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    .head {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      color: themed("n8");
      font-size: 14px;
      padding: 12px 0;
      p {
        padding: 0 12px;
      }
      p:nth-of-type(1) {
        margin-bottom: 16px;
      }
    }
    /deep/.el-table:not(.no-blank) .el-table__empty-text {
      height: 0;
    }
  }
}
</style>
